<template>
    <main class="layout--generic">
        <AppPageHeader
            title="404"
            :headerImg="null"
        />
        <section class="content gc gc-intro">
            <article>
                <h2>That page or post no longer exists</h2>
                <p>Double check the URL, otherwise the page may no longer be available.</p>
                <router-link to="/">Return to the homepage</router-link>
            </article>
        </section>
    </main>
</template>
